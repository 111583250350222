import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1064.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
     

     <path d="M2 5398 l3 -5403 5 5380 5 5380 70 0 c39 0 2429 1 5313 3 5235 2
5242 2 5242 22 0 20 -7 20 -5320 20 l-5320 0 2 -5402z"/>
<path d="M5170 7320 c-250 -34 -532 -211 -679 -426 -151 -223 -217 -498 -183
-759 l7 -50 65 85 c81 106 198 229 272 286 49 38 59 52 77 106 46 137 131 244
267 334 102 68 187 98 315 114 197 25 398 -28 565 -145 56 -40 114 -102 114
-122 0 -12 -89 -102 -138 -142 -52 -40 -49 -52 3 -16 39 26 55 31 55 17 0 -12
32 -11 48 1 7 6 11 16 8 24 -4 9 5 13 33 13 36 0 39 2 51 43 8 23 13 46 12 50
-1 4 -3 15 -5 25 -1 9 -6 23 -10 29 -4 7 -3 22 3 33 11 21 16 88 9 125 -2 11
-16 26 -31 33 -18 9 -25 19 -21 30 4 9 2 13 -4 9 -8 -5 -85 49 -159 111 -6 5
-38 22 -72 38 -41 19 -58 31 -49 36 7 5 0 6 -15 2 -17 -4 -28 -2 -28 4 0 6
-15 13 -32 17 -18 3 -42 10 -53 15 -44 19 -90 30 -121 30 -19 0 -34 5 -34 10
0 6 -9 10 -19 10 -11 0 -23 5 -26 10 -3 6 -39 11 -78 12 l-72 1 65 7 65 7 -75
0 c-41 0 -100 -3 -130 -7z"/>
<path d="M5535 6900 c-19 -8 -19 -9 2 -9 12 -1 25 4 28 9 7 12 -2 12 -30 0z"/>
<path d="M5445 6865 c-22 -13 -57 -34 -77 -45 -70 -38 -173 -115 -328 -244
-52 -44 -97 -83 -100 -86 -26 -29 -121 -100 -129 -96 -5 3 -12 0 -14 -6 -3 -8
-6 -6 -6 4 -1 15 -5 16 -28 7 -17 -6 -30 -6 -36 0 -5 5 -17 7 -26 3 -14 -5
-14 -3 2 12 15 16 23 36 13 36 -5 0 -75 -69 -151 -151 -136 -144 -333 -461
-422 -678 -22 -52 -23 -86 -2 -48 43 76 298 337 439 449 143 113 334 238 467
306 196 100 346 164 353 152 4 -6 12 -48 19 -93 29 -205 41 -297 47 -354 4
-35 11 -63 17 -63 28 0 382 225 485 309 82 66 212 201 195 201 -6 0 -21 -11
-34 -25 -28 -28 -39 -31 -39 -10 0 8 -9 15 -21 15 -11 0 -17 -4 -14 -10 3 -5
2 -10 -4 -10 -5 0 -13 5 -16 11 -4 6 -13 8 -20 5 -8 -3 -19 2 -25 10 -13 15
-50 19 -50 5 0 -4 -22 -6 -50 -3 -46 4 -50 3 -50 -16 0 -19 -3 -20 -26 -12
-14 6 -49 10 -78 10 -37 0 -59 5 -72 18 -25 23 -52 78 -60 125 -4 21 -12 41
-19 44 -7 2 -16 14 -19 26 -3 13 -14 32 -24 43 -29 31 -42 60 -42 93 0 24 -3
29 -17 25 -14 -5 -15 -4 -4 4 8 6 19 8 25 6 10 -4 41 34 34 41 -16 16 -58 11
-93 -10z"/>
<path d="M6680 6833 c-103 -167 -335 -413 -590 -624 -30 -25 -116 -90 -190
-143 -74 -54 -136 -101 -138 -105 -1 -4 33 -28 75 -54 135 -83 134 -80 56
-143 -26 -22 -54 -44 -60 -51 -24 -22 -198 -101 -238 -108 -22 -4 -44 -11 -50
-15 -5 -4 -53 -13 -105 -19 -52 -7 -108 -16 -125 -20 -16 -4 -43 -4 -60 1 -16
4 -62 10 -102 12 -39 2 -86 11 -104 20 -18 9 -42 16 -55 16 -13 0 -27 6 -31
13 -4 6 -25 25 -45 40 -21 15 -38 34 -38 42 0 7 -9 17 -20 20 -11 4 -27 18
-35 33 -24 45 -77 98 -82 85 -9 -23 -25 -13 -21 12 3 13 2 22 -3 20 -12 -8
-39 6 -39 21 0 8 9 14 20 14 11 0 20 5 20 10 0 6 -5 10 -11 10 -7 0 -10 17 -7
45 2 33 0 44 -9 40 -19 -7 -15 13 5 24 9 6 11 11 5 11 -7 0 -21 -8 -32 -19
-10 -10 -45 -38 -77 -62 -81 -60 -176 -158 -170 -175 3 -8 31 -52 62 -99 152
-229 429 -404 701 -444 393 -58 788 103 1014 414 37 51 119 190 119 201 0 12
-31 1 -39 -13 -6 -10 -11 -13 -11 -7 0 7 -7 15 -16 18 -12 5 -15 2 -10 -11 4
-14 3 -14 -7 1 -18 30 -70 76 -84 76 -7 0 -13 5 -13 11 0 6 7 9 15 5 24 -9 18
3 -16 31 l-31 26 48 41 c156 133 328 340 427 516 61 108 153 341 135 340 -2 0
-19 -26 -38 -57z m-1247 -1280 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z"/>
<path d="M5204 6774 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
<path d="M5870 6570 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M5765 6530 c-10 -11 -15 -23 -12 -27 4 -3 7 -1 7 5 0 7 7 12 15 12 8
0 15 7 15 15 0 20 -3 19 -25 -5z"/>
<path d="M3267 4163 c-4 -3 -7 -118 -7 -255 l0 -248 129 0 c125 0 129 1 135
23 15 55 13 57 -79 57 l-85 0 0 65 0 65 50 2 c28 0 56 1 63 2 11 1 27 62 19
79 -2 4 -32 7 -68 7 l-64 0 0 65 0 65 80 0 c69 0 80 2 80 18 0 9 3 27 6 40 6
22 6 22 -123 22 -71 0 -133 -3 -136 -7z"/>
<path d="M3570 4138 c4 -18 18 -69 31 -113 30 -104 29 -130 -9 -250 -18 -55
-32 -104 -32 -108 0 -4 21 -7 47 -5 l48 3 24 97 c13 54 27 95 31 93 4 -3 11
-22 15 -42 3 -21 14 -64 22 -95 l16 -58 49 0 c26 0 48 1 48 3 0 1 -16 52 -35
112 -39 124 -42 154 -19 216 8 24 24 74 34 112 l19 67 -49 0 c-55 0 -49 10
-80 -128 -12 -52 -27 -74 -34 -50 -2 7 -12 50 -22 96 l-17 82 -48 0 -47 0 8
-32z"/>
<path d="M3920 4148 c-20 -22 -21 -31 -18 -240 2 -161 6 -220 16 -230 9 -9 49
-14 120 -16 98 -3 109 -2 130 18 20 19 23 30 20 83 l-3 61 -42 4 -42 3 -3 -43
-3 -43 -47 -3 -48 -3 0 176 0 176 48 -3 c47 -3 47 -3 50 -40 3 -38 4 -38 48
-38 l44 0 0 58 c0 36 -6 66 -16 80 -14 20 -23 22 -124 22 -100 0 -111 -2 -130
-22z"/>
<path d="M4260 3915 l0 -255 130 0 130 0 0 40 0 39 -82 3 -83 3 -3 62 -3 62
68 3 68 3 3 43 3 42 -71 0 -71 0 3 63 3 62 83 3 82 3 0 39 0 40 -130 0 -130 0
0 -255z"/>
<path d="M4580 3916 l0 -256 115 0 c115 0 115 0 115 24 0 13 3 31 6 40 5 13
-6 16 -68 18 l-73 3 0 210 0 210 -47 3 -48 3 0 -255z"/>
<path d="M4860 3915 l0 -255 115 0 115 0 0 40 0 39 -67 3 -68 3 -3 213 -2 212
-45 0 -45 0 0 -255z"/>
<path d="M5131 3915 l-3 -255 131 0 c98 0 131 3 132 13 1 6 3 24 5 40 l3 27
-85 0 -85 0 3 67 c3 60 5 68 23 69 11 0 42 1 68 2 l48 2 -3 38 -3 37 -67 3
-68 3 0 64 0 65 79 0 79 0 4 40 4 40 -131 0 -130 0 -4 -255z"/>
<path d="M5460 3925 c0 -135 -1 -248 -2 -252 -2 -5 18 -9 44 -11 l47 -3 3 158
c2 87 6 151 10 143 3 -8 21 -78 40 -155 l33 -140 68 -3 67 -3 0 256 0 255 -48
0 -48 0 -2 -152 c0 -84 -1 -155 -2 -158 -2 -8 -45 167 -60 243 l-14 67 -68 0
-68 0 0 -245z"/>
<path d="M5872 4154 c-22 -15 -22 -19 -22 -234 0 -270 -5 -260 136 -260 139 0
154 11 154 116 l0 51 -47 -1 c-51 -1 -48 3 -52 -69 -1 -15 -9 -18 -48 -15
l-48 3 -1 130 c-1 72 1 149 3 173 l5 42 44 0 44 0 0 -38 c0 -21 2 -41 4 -43 3
-2 25 -3 50 -1 l46 4 0 50 c0 49 -13 86 -34 100 -6 4 -56 8 -111 8 -75 0 -106
-4 -123 -16z"/>
<path d="M6204 3915 l0 -255 133 0 133 0 0 40 0 39 -82 3 -83 3 0 64 0 63 65
2 65 1 3 43 3 42 -70 0 -71 0 0 65 0 65 85 0 85 0 0 40 0 40 -132 0 -133 0 -1
-255z"/>
<path d="M6540 4150 c-16 -16 -20 -33 -20 -94 0 -40 5 -86 10 -103 13 -35 66
-68 127 -78 54 -9 65 -23 61 -82 -3 -45 -5 -48 -32 -51 -15 -2 -39 -1 -52 2
-20 5 -24 13 -24 46 l0 40 -45 0 -45 0 0 -65 c0 -95 14 -105 145 -105 135 0
145 8 145 121 0 59 -5 89 -17 110 -21 34 -86 69 -131 69 -39 0 -54 25 -50 83
l3 42 50 0 50 0 3 -42 3 -43 45 0 44 0 0 65 c0 95 -14 105 -147 105 -90 0
-106 -3 -123 -20z"/>
<path d="M6880 3931 l0 -239 25 -16 c18 -12 49 -16 120 -16 88 0 98 2 120 25
l25 24 0 231 0 230 -50 0 -50 0 0 -216 0 -215 -47 3 -48 3 -3 213 -2 212 -45
0 -45 0 0 -239z"/>
<path d="M7250 3915 l0 -255 90 0 c116 0 159 19 188 83 23 52 11 137 -22 159
-7 5 -7 17 3 40 8 18 16 65 18 103 4 61 1 74 -17 98 -21 27 -23 27 -141 27
l-119 0 0 -255z m178 113 l-3 -63 -40 0 -40 0 -3 49 c-4 68 1 76 48 76 l41 0
-3 -62z m10 -220 c3 -54 1 -58 -21 -64 -14 -3 -36 -4 -48 -2 -21 3 -24 9 -27
57 -4 75 -2 79 50 72 l43 -6 3 -57z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
